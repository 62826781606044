import React from "react";
import cx from "classnames";
import Photo from "@components/photo";
const Media = ({ media, layout, aspect = false }) => {
  if (!media) return null;
  return (
    <div
      className={cx("media-wrap", {
        "mx-auto": media.maxWidth,
      })}
      style={{
        maxWidth: media.maxWidth ? media.maxWidth : "none",
      }}
    >
      {media.photo && (
        <Photo
          photo={media.photo}
          className={cx("relative", {
            "aspect-video": aspect == "video",
            "aspect-square": aspect == "square",
          })}
          width={2200}
          layout={layout}
        />
      )}
    </div>
  );
};

export default Media;
