import React, { useEffect } from "react";
import cx from "classnames";
import { m, useAnimation } from "framer-motion";
import useInView from "react-cool-inview";

const FadeWrap = ({
  children,
  offset = 0,
  delay = 0,
  smallMargin = false,
  ...rest
}) => {
  const controls = useAnimation();

  let rootMargin =
    typeof window !== "undefined"
      ? `${window.outerHeight * -0.4}px 0px`
      : "0px";

  if (smallMargin) {
    rootMargin = "-50px 0px";
  }
  const { observe, inView } = useInView(
    {
      threshold: 0,
      rootMargin: rootMargin,
      onEnter: ({ scrollDirection }) => {
        if (scrollDirection.vertical == "up") {
          controls.start("visible");
        }
      },
    },
    [controls]
  );

  if (inView) {
    controls.start("visible");
  }

  const defaultVariants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: delay, ease: "easeOut" },
    },
    hidden: {
      opacity: 0,
      y: offset,
      transition: { duration: 1 },
    },
  };

  return (
    <m.div
      ref={observe}
      initial="hidden"
      variants={defaultVariants}
      animate={controls}
      {...rest}
    >
      {children}
    </m.div>
  );
};

export default FadeWrap;
