import React from "react";
import cx from "classnames";
import Icon from "@components/icon";
import Media from "@components/media";
import Phrase from "@components/phrase";
import BlockContent from "@components/block-content";
import { useUpdateSiteTheme } from "@lib/context";
import useInView from "react-cool-inview";
import FadeWrap from "@components/fade-wrap";
import Marquee from "react-fast-marquee";
const Interactive = ({ data = {} }) => {
  const updateSiteTheme = useUpdateSiteTheme();

  const { style, backgroundColor, sections, reduceMargin, reduceMinHeight } =
    data;
  const foregroundColor = backgroundColor.title == "Slate" ? "white" : "black";

  const { observe, inView } = useInView({
    threshold: [0.2, 0.4, 0.6, 0.8, 1],
    onEnter: ({ scrollDirection }) => {
      if (style !== "longText") {
        updateSiteTheme(backgroundColor.title.toLowerCase());
      }
    },
    onLeave: ({ scrollDirection }) => {
      if (style !== "longText") {
        updateSiteTheme(false);
      }
    },
  });

  return (
    <section
      ref={observe}
      className={cx(
        "interactive",
        "grid",
        `bg-${backgroundColor.value}`,
        `text-${foregroundColor}`,
        "relative",

        `style--${style}`,
        {
          "pt-[25vh]": style !== "longText" && !reduceMinHeight,
          "mb-[30vh]": style !== "longText" && !reduceMargin,
        }
      )}
      data-bg={backgroundColor.title.toLowerCase()}
      data-theme={
        style == "longText" ? backgroundColor.title.toLowerCase() : false
      }
    >
      {style == "default" && (
        <div className={cx("interactive-sections")}>
          {sections.map((section, idx) => {
            if (section._type == "gallery") {
              return (
                <div
                  key={idx}
                  className={cx("grid", "grid-cols-4", "md:grid-cols-20")}
                >
                  <div className={cx("col-span-4", "md:col-span-20")}>
                    {section?.media?.map((media, idx) => (
                      <Media key={idx} media={media} layout="intrinsic" />
                    ))}
                  </div>
                </div>
              );
            }
            if (section._type == "section") {
              return (
                <div
                  key={idx}
                  className={cx(
                    "grid",
                    "grid-cols-4",
                    "md:grid-cols-20",
                    "p-4"
                  )}
                >
                  <div className={cx("col-span-4", "md:col-span-9")}>
                    <FadeWrap delay="0.05" className={"h-full"}>
                      <div
                        className={cx(
                          "sticky-wrap",
                          "md:sticky",
                          "mt-[14px]",
                          "top-0",
                          "align-items-center",
                          "flex",
                          {
                            "md:h-screen": !reduceMinHeight,
                          }
                        )}
                      >
                        <div
                          className={cx(
                            "flex",
                            "md:hidden",
                            "justify-center",
                            "w-full"
                          )}
                        >
                          <Phrase
                            phrase={section.phrase + "-"}
                            typeStyle={"text-subheader-2 uppercase"}
                            vertical={true}
                            dividerMaxWidth="300px"
                            color={
                              backgroundColor.title == "Slate"
                                ? "#ffffff"
                                : "#000000"
                            }
                          />
                        </div>
                        <div
                          className={cx("hidden", "md:flex", "w-full")}
                          data-order="1"
                        >
                          <Phrase
                            phrase={section.phrase + "-"}
                            phraseMaxWidth={
                              section.phraseMaxWidth
                                ? section.phraseMaxWidth
                                : "none"
                            }
                            typeStyle={
                              "text-subheader-2 uppercase md:mx-0 md:justify-start"
                            }
                            vertical={false}
                            delay={0}
                            dividerMaxWidth="1000px"
                            color={
                              backgroundColor.title == "Slate"
                                ? "#ffffff"
                                : "#000000"
                            }
                          />
                        </div>
                      </div>
                    </FadeWrap>
                  </div>
                  <div
                    className={cx("col-span-4", "md:col-span-11")}
                    data-order="2"
                  >
                    <FadeWrap
                      delay="0.25"
                      className={cx({
                        "md:mt-[50px]": !reduceMinHeight,
                      })}
                    >
                      <div className={cx("media-items")}>
                        <div className={cx("flex", "items-center")}>
                          <div
                            className={cx(
                              "w-full",
                              "flex",
                              "items-center",
                              "text-left",

                              "wysiwyg-content",
                              {
                                "md:min-h-[100vh] h-full mb-[125px]":
                                  !reduceMinHeight,
                              }
                            )}
                          >
                            <BlockContent blocks={section.body} />
                          </div>
                        </div>
                        {section?.media?.map((media, idx) => (
                          <div
                            key={idx}
                            className={cx(
                              "flex",
                              "flex-wrap",
                              "items-center",
                              "mb-[10px]",
                              "md:mb-[90px]"
                            )}
                          >
                            <div className={cx("w-full")}>
                              <Media media={media} layout="intrinsic" />
                            </div>
                            {idx !== section.media.length - 1 && (
                              <div
                                className={cx(
                                  "flex",
                                  "md:hidden",
                                  "justify-center",
                                  "w-full",
                                  "mt-[20px]"
                                )}
                              >
                                <Phrase
                                  phrase={"-"}
                                  typeStyle={"text-subheader uppercase"}
                                  vertical={true}
                                  dividerMaxWidth="300px"
                                  color={
                                    backgroundColor.title == "Slate"
                                      ? "#ffffff"
                                      : "#000000"
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </FadeWrap>
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
      {style == "longText" && (
        <div
          className={cx(
            "interactive-sections",
            "md:pb-[125px]",
            "pt-[125px]",
            "site-container"
          )}
        >
          {sections.map((section, idx) => {
            if (section._type == "gallery") {
              return (
                <div
                  key={idx}
                  className={cx("grid", "grid-cols-4", "md:grid-cols-20")}
                >
                  <div className={cx("col-span-4", "md:col-span-20")}>
                    {section?.media?.map((media, idx) => (
                      <Media key={idx} media={media} layout="intrinsic" />
                    ))}
                  </div>
                </div>
              );
            }
            if (section._type == "section") {
              return (
                <div
                  key={idx}
                  className={cx(
                    "grid",
                    "grid-cols-4",
                    "md:grid-cols-20",
                    "p-4"
                  )}
                >
                  <div
                    className={cx(
                      "col-span-4",
                      "md:col-span-5",
                      "md:col-start-3"
                    )}
                  >
                    <div
                      className={cx(
                        "mt-[2px]",
                        "top-0",
                        "align-items-center",
                        "flex",
                        "sticky",
                        "top-[40px]"
                      )}
                    >
                      <div
                        className={cx(
                          "flex",
                          "md:hidden",
                          "justify-center",
                          "w-full"
                        )}
                      >
                        <Phrase
                          phrase={section.phrase + "-"}
                          phraseMaxWidth={
                            section.phraseMaxWidth
                              ? section.phraseMaxWidth
                              : "none"
                          }
                          typeStyle={"text-subheader uppercase"}
                          vertical={true}
                          dividerMaxWidth="50px"
                          verticalEndHeight="50px"
                          noAnim={true}
                          hideLast={true}
                          color={
                            backgroundColor.title == "Slate"
                              ? "#ffffff"
                              : "#000000"
                          }
                        />
                      </div>
                      <div className={cx("hidden", "md:flex", "w-full")}>
                        <Phrase
                          phrase={section.phrase + "-"}
                          phraseMaxWidth={
                            section.phraseMaxWidth
                              ? section.phraseMaxWidth
                              : "none"
                          }
                          typeStyle={
                            "text-subheader uppercase md:mx-0 md:justify-start"
                          }
                          delay="0"
                          vertical={false}
                          dividerMaxWidth="1000px"
                          noAnim={true}
                          color={
                            backgroundColor.title == "Slate"
                              ? "#ffffff"
                              : "#000000"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className={cx("col-span-4", "md:col-span-11")}>
                    <div className={cx("media-items")}>
                      <div className={cx("flex", "items-center")}>
                        <div
                          className={cx(
                            "mb-[40px]",
                            "w-full",
                            "flex",
                            "items-center",
                            "text-left",
                            "md:text-left",
                            "text-large",
                            "mt-[4px]",
                            "md:mt-0",
                            "font-normal",
                            "font-sansRegular",
                            "wysiwyg-content"
                          )}
                        >
                          <BlockContent blocks={section.body} />
                        </div>
                      </div>
                      {section?.media?.map((media, idx) => (
                        <div
                          key={idx}
                          className={cx(
                            "flex",
                            "flex-wrap",
                            "items-center",
                            "mb-[10px]"
                          )}
                        >
                          <div className={cx("w-full")}>
                            <Media media={media} layout="intrinsic" />
                          </div>
                          {idx !== section.media.length - 1 && (
                            <div
                              className={cx(
                                "flex",
                                "md:hidden",
                                "justify-center",
                                "w-full",
                                "mt-[20px]"
                              )}
                            >
                              <Phrase
                                phrase={"-"}
                                typeStyle={"text-subheader uppercase"}
                                vertical={true}
                                dividerMaxWidth="300px"
                                color={
                                  backgroundColor.title == "Slate"
                                    ? "#ffffff"
                                    : "#000000"
                                }
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
      {style == "vertical" && (
        <div
          className={cx(
            "interactive-sections",
            "grid",
            "grid-cols-4",
            "md:grid-cols-20",
            "items-center"
          )}
        >
          {sections.map((section, idx) => {
            if (section._type == "gallery") {
              return (
                <div
                  key={idx}
                  className={cx(
                    "section-gallery",
                    "col-span-4",
                    "md:col-span-20",
                    "mb-interactive",
                    "relative",
                    "z-30"
                  )}
                >
                  <Marquee speed="75" gradient={false}>
                    <div className={cx("w-full", "aspect-video")}>
                      {section?.media?.map((media, idx) => (
                        <Media key={idx} media={media} layout="intrinsic" />
                      ))}
                    </div>
                  </Marquee>
                </div>
              );
            }
            return (
              <div
                key={idx}
                className={cx("col-span-4", "md:col-span-18", "md:col-start-2")}
              >
                <div className={cx("p-gutter", "md:p-0", "site-container")}>
                  <Phrase
                    phrase={section.phrase}
                    phraseMaxWidth={
                      section.phraseMaxWidth ? section.phraseMaxWidth : "none"
                    }
                    typeStyle={"text-lg-1-mobile md:text-lg-1 text-center"}
                    vertical={true}
                    dividerMaxWidth="400px"
                    customThreshold={[0.25, 0.75]}
                    isStatic={true}
                    color={
                      backgroundColor.title == "Slate" ? "#ffffff" : "#000000"
                    }
                  />
                  <div className={cx("mb-gutter")}>
                    {section.body && (
                      <div
                        className={cx(
                          "mb-10",
                          "wysiwyg-content",
                          "md:max-w-[67%]",
                          "md:mx-auto",
                          "relative",
                          "z-30"
                        )}
                      >
                        <BlockContent blocks={section.body} />
                      </div>
                    )}
                    {section?.media?.map((media, idx) => (
                      <div className={cx("mx-auto")} key={idx}>
                        <Media key={idx} media={media} layout="intrinsic" />
                      </div>
                    ))}
                  </div>
                  {section.caption && (
                    <div
                      className={cx(
                        "caption",
                        "text-caption",
                        "text-center",
                        "mt-2"
                      )}
                    >
                      {section.caption}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {style == "cards" && (
        <div
          className={cx(
            "interactive-sections",
            "grid",
            "p-gutter",
            "grid-cols-4",
            "sm:grid-cols-20",
            "items-center"
          )}
        >
          <div
            className={cx(
              "col-span-4",
              "col-start-1",
              "sm:col-span-18",
              "sm:col-start-2"
            )}
          >
            {sections.map((section, idx) => (
              <div
                key={idx}
                className={cx("flex", "flex-col", "justify-end", {
                  "mb-[50vh]": idx !== sections.length - 1,
                })}
              >
                <Phrase
                  phrase={section.phrase}
                  phraseMaxWidth={
                    section.phraseMaxWidth ? section.phraseMaxWidth : "none"
                  }
                  typeStyle={"text-lg-1-mobile md:text-lg-1 text-center"}
                  vertical={true}
                  dividerMaxWidth="400px"
                  isStatic={true}
                  customThreshold={[0.25, 0.75]}
                  color={
                    backgroundColor.title == "Slate" ? "#ffffff" : "#000000"
                  }
                />
                <div
                  className={cx(
                    "card",
                    "mt-0",
                    "mx-auto",
                    "max-w-[1000px]",
                    "flex",
                    "flex-col",
                    "sm:flex-row",
                    "bg-white",
                    "relative",
                    "z-30",
                    "rounded-[3px]",
                    "p-2",
                    "gap-gutter",
                    "text-black"
                  )}
                >
                  <div className={cx("w-full")}>
                    {section?.media?.map((media, idx) => (
                      <Media key={idx} media={media} layout="static" />
                    ))}
                  </div>
                  <div className={cx("w-full", "flex", "flex-col")}>
                    <div
                      className={cx(
                        "text-large",
                        "font-sansRegular",
                        "leading-[24px]",
                        "wysiwyg-content",
                        "mb-20"
                      )}
                    >
                      <BlockContent blocks={section.body} />
                    </div>
                    {section.caption && (
                      <div
                        className={cx(
                          "caption",
                          "text-xs",
                          "font-sansRegular",
                          "text-left",
                          "mt-auto"
                        )}
                      >
                        {section.caption}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default Interactive;
